import classNames from "classnames";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import Header from "../../Components/Header/Header";
import styles from "./FaqPage.module.scss";
import { ReactComponent as DiscordIcon } from "../../assets/icons/discord.svg";

function FaqPage() {
  return (
    <div className={styles["container"]}>
      <Header />
      <Container className={styles["main"]}>
        <Row className="px-md-0 px-3">
          <Col sm={12} className="position-relative text-center">
            <h1
              className={classNames(styles["title"], "font-blenny text-white")}
            >
              JUST THE FACTS
            </h1>
          </Col>

          <Col
            sm={12}
            className={classNames("text-center", styles["text-sub"])}
          >
            <h3 className="text-uppercase text-yellow-100 font-acierdisplay letterspace5px">
              STUFF TO KNOW
            </h3>
          </Col>

          <Col
            sm={12}
            className={classNames(
              "text-center text-white font-barricada mt-7",
              styles["description"]
            )}
          >
            <Accordion defaultActiveKey="0">
              <Accordion.Item className={styles["faq-sec"]} eventKey="0">
                <Accordion.Header className="font-acierdisplay">
                  Do You Have A Verified Contract?
                </Accordion.Header>
                <Accordion.Body
                  className={classNames(styles["faq-body"], "text-start")}
                >
                  <p className="font-acierdisplay mb-0">
                    <a
                      className="text-decoration-none text-white"
                      href="https://polygonscan.com/address/0xF0bf68732e3b025e08249a667d5c143A1948f37E"
                      target="_blank"
                    >
                      0xF0bf68732e3b025e08249a667d5c143A1948f37E
                    </a>
                  </p>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item className={styles["faq-sec"]} eventKey="1">
                <Accordion.Header className="font-acierdisplay">
                  When Will These Drop?
                </Accordion.Header>
                <Accordion.Body
                  className={classNames(styles["faq-body"], "text-start")}
                >
                  <p className="font-acierdisplay mb-0 text-white">
                    Sept 25, 2023
                  </p>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item className={styles["faq-sec"]} eventKey="3">
                <Accordion.Header className="font-acierdisplay">
                  What is The Minting Price
                </Accordion.Header>
                <Accordion.Body
                  className={classNames(styles["faq-body"], "text-start")}
                >
                  <p className="font-acierdisplay mb-0 text-white">
                    Buzzy 50 MATIC
                    <span className="d-sm-block"> Buzzmaker 50,000 MATIC </span>
                  </p>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item className={styles["faq-sec"]} eventKey="4">
                <Accordion.Header className="font-acierdisplay">
                  How Do I Mint Coffee Wars NFT?
                </Accordion.Header>
                <Accordion.Body
                  className={classNames(styles["faq-body"], "text-start")}
                >
                  <ul className="font-acierdisplay mb-0 text-white p-0 m-0 list-unstyled">
                    <li>
                      1) Download the metamask.io extension for the Chrome/Brave
                      browser or app on mobile. This will allow you to make
                      purchases with MATIC and can be found in the extensions
                      tab. If you are on mobile, you must use the Metamask App
                      Browser.
                    </li>
                    <li>
                      2) You can purchase MATIC through the Metamask Wallet
                      using Wyre or Send MATIC from an exchange like
                      Coinbase.
                    </li>
                    <li>
                      3) Click on Connect at the top of the page and connect
                      your Metamask. Once joined, you will be able to purchase
                      the NFTs in the mint section. You will be prompted to sign
                      your transaction. FYI, there will be a fee associated with
                      every transaction related to gas prices.
                    </li>
                    <li>
                      4) Once you have made your purchase, your Coffee Wars NFTs
                      will be viewable in your wallet and on OpenSea.
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item className={styles["faq-sec"]} eventKey="5">
                <Accordion.Header className="font-acierdisplay">
                  What Are the Terms of Services?
                </Accordion.Header>
                <Accordion.Body
                  className={classNames(styles["faq-body"], "text-start")}
                >
                  <h1 className="font-acierdisplay mb-0 text-white">
                    OVERVIEW
                  </h1>
                  <p className="font-acierdisplay mb-0 text-white">
                    This website is operated by Foam Productions, LLC.
                    Throughout the site, the terms “we”, “us,” and “our” refer
                    to Foam Productions, LLC. Foam Productions, LLC offers this
                    website, including all information, tools, and services
                    available from this site to you, the user, conditioned upon
                    your acceptance of all terms, conditions, policies, and
                    notices stated here. By visiting our site and/or purchasing
                    an NFT from us, you engage in our “Service” and agree to be
                    bound by the following terms and conditions (“Terms of
                    Service”, “Terms”), including those additional terms and
                    conditions and policies referenced herein and/or available
                    by hyperlink. These Terms of Service apply to all users of
                    the site, including without limitation users who are
                    browsers, vendors, customers, merchants, and/ or
                    contributors of content.
                  </p>

                  <p className="font-acierdisplay mb-0 text-white">
                    Please read these Terms of Service carefully before
                    accessing or using our website. By accessing or using any
                    part of the site, you agree to be bound by these Terms of
                    Service. If you do not agree to all the terms and conditions
                    of this agreement, then you may not access the website or
                    use any services.
                  </p>
                  <p className="font-acierdisplay mb-0 text-white">
                    Any new features or tools which are added to the
                    CoffeeWarsNFT.com website shall also be subject to the Terms
                    of Service. You can review the most current version of the
                    Terms of Service at any time on this page. We reserve the
                    right to update, change, or replace any part of these Terms
                    of Service by posting updates and/or changes to our website.
                    It is your responsibility to check this page periodically
                    for changes. Your continued use of or access to the website
                    following the posting of any changes constitutes acceptance
                    of those changes.
                  </p>
                  <h5 className="text-orange font-acierdisplay mb-0">
                    SECTION 1 - ONLINE TERMS
                  </h5>
                  <p className="font-acierdisplay mb-0 text-white">
                    By agreeing to these Terms of Service, you represent that
                    you are at least the age of majority in your state or
                    province of residence, or that you are the age of majority
                    in your state or province of residence and you have given us
                    your consent to allow any of your minor dependents to use
                    this sit.
                  </p>
                  <p className="font-acierdisplay mb-0 text-white">
                    You may not use our NFT for any illegal or unauthorized
                    purpose nor may you, in the use of the Service, violate any
                    laws in your jurisdiction (including but not limited to
                    copyright laws).
                  </p>
                  <p className="font-acierdisplay mb-0 text-white">
                    You must not transmit any worms or viruses or any code of a
                    destructive nature. A breach or violation of any of the
                    Terms will result in an immediate termination of your
                    Services.
                  </p>
                  <h5 className="text-orange font-acierdisplay mb-0">
                    SECTION 2 - GENERAL CONDITIONS
                  </h5>
                  <p className="font-acierdisplay mb-0 text-white">
                    We reserve the right to refuse service to anyone for any
                    reason at any time. You understand that your content may be
                    transferred unencrypted and involve (a) transmissions over
                    various networks; and (b) changes to conform and adapt to
                    technical requirements of connecting networks or devices.
                  </p>
                  <p className="font-acierdisplay mb-0 text-white">
                    You agree not to reproduce, duplicate, copy, or exploit any
                    portion of the Service, use of the Service, NFTs or access
                    to the Service or any contact on the website through which
                    the service is provided, without express written permission
                    by us.
                  </p>
                  <p className="font-acierdisplay mb-0 text-white">
                    The headings used in this agreement are included for
                    convenience only and will not limit or otherwise affect
                    these Terms.
                  </p>
                  <h5 className="text-orange font-acierdisplay">
                    SECTION 3 - ACCURACY, COMPLETENESS AND TIMELINESS OF
                    INFORMATION
                  </h5>
                  <p className="font-acierdisplay mb-0 text-white">
                    We are not responsible if information made available on this
                    site is not accurate, complete, or current. The material on
                    this site is provided for general information only and
                    should not be relied upon or used as the sole basis for
                    making decisions without consulting primary, more accurate,
                    more complete or more timely sources of information. Any
                    reliance on the material on this site is at your own risk.
                  </p>
                  <p className="font-acierdisplay mb-0 text-white">
                    This site may contain certain historical information.
                    Historical information, necessarily, is not current and is
                    provided for your reference only. We reserve the right to
                    modify the contents of this site at any time, but we have no
                    obligation to update any information on our site. You agree
                    that it is your responsibility to monitor changes to our
                    site.
                  </p>
                  <h5 className="text-orange font-acierdisplay">
                    SECTION 4 - MODIFICATIONS TO THE SERVICE AND PRICES
                  </h5>
                  <p className="font-acierdisplay mb-0 text-white">
                    Prices for our NFTs are subject to change without notice.
                  </p>
                  <p className="font-acierdisplay mb-0 text-white">
                    We reserve the right at any time to modify or discontinue
                    the Service (or any part or content thereof) without notice
                    at any time.
                  </p>
                  <p className="font-acierdisplay mb-0 text-white">
                    We shall not be liable to you or to any third-party for any
                    modification, price change, suspension, or discontinuance of
                    the Service.
                  </p>
                  <h5 className="text-orange font-acierdisplay">
                    SECTION 5 - PRODUCTS OR SERVICES (if applicable)
                  </h5>
                  <p className="font-acierdisplay mb-0 text-white">
                    Certain products or services may be available exclusively
                    online through the website. These products or services may
                    have limited quantities and are subject to availability. We
                    have made every effort to display as accurately as possible
                    the colors and images of our products that appear on the
                    website. We cannot guarantee that your computer monitor's
                    display of any color will be accurate.
                  </p>
                  <p className="font-acierdisplay mb-0 text-white">
                    We reserve the right, but are not obligated, to limit the
                    sales of our products or Services to any person, geographic
                    region, or jurisdiction. We may exercise this right on a
                    case- by-case basis. We reserve the right to limit the
                    quantities of any products that we offer. All descriptions
                    of products or product pricing are subject to change at any
                    time without notice, at the sole discretion of Foam
                    Productions, LLC. We reserve the right to discontinue any
                    product at any time. Any offer for any product or service
                    made on this site is void where prohibited.
                  </p>
                  <p className="font-acierdisplay mb-0 text-white">
                    We do not warrant that the quality of any products,
                    services, information, or other material purchased or
                    obtained by you will meet your expectations or that any
                    errors in the Service will be corrected.
                  </p>
                  <h5 className="text-orange font-acierdisplay">
                    SECTION 6 - OPTIONAL TOOLS
                  </h5>
                  <p className="font-acierdisplay mb-0 text-white">
                    We may provide you with access to third-party tools which we
                    neither monitor nor have any control nor input over.
                  </p>
                  <p className="font-acierdisplay mb-0 text-white">
                    You acknowledge and agree that we provide access to such
                    tools “as is” and “as available” without any warranties,
                    representations, or conditions of any kind and without any
                    endorsement. We shall have no liability whatsoever arising
                    from or relating to your use of optional third-party tools.
                  </p>
                  <p className="font-acierdisplay mb-0 text-white">
                    Any use by you of optional tools offered through the site is
                    entirely at your own risk and discretion and you should
                    ensure that you are familiar with and approve of the terms
                    on which tools are provided by the relevant third-party
                    provider(s).
                  </p>
                  <p className="font-acierdisplay mb-0 text-white">
                    We may also, in the future, offer new services and/or
                    features through the website (including, the release of new
                    tools and resources). Such new features and/or services
                    shall also be subject to these Terms of Service.
                  </p>
                  <h5 className="text-orange font-acierdisplay">
                    SECTION 7 - USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS
                  </h5>
                  <p className="font-acierdisplay mb-0 text-white">
                    If, at our request, you send certain specific submissions
                    (for example contest entries) or without a request from us
                    you send creative ideas, suggestions, proposals, plans, or
                    other materials, whether online, by email, by postal mail,
                    or otherwise (collectively, 'comments'), you agree that we
                    may, at any time, without restriction, edit, copy, publish,
                    distribute, translate, and otherwise use in any medium any
                    comments that you forward to us. We are and shall be under
                    no obligation (1) to maintain any comments in confidence;
                    (2) to pay compensation for any comments; or (3) to respond
                    to any comments.
                  </p>
                  <p className="font-acierdisplay mb-0 text-white">
                    We may, but have no obligation to, monitor, edit, or remove
                    content that we determine in our sole discretion are
                    unlawful, offensive, threatening, libelous, defamatory,
                    pornographic, obscene, or otherwise objectionable or
                    violates any party’s intellectual property or these Terms of
                    Service.
                  </p>
                  <p className="font-acierdisplay mb-0 text-white">
                    You agree that your comments will not violate any right of
                    any third-party, including copyright, trademark, privacy,
                    personality, or other personal or proprietary right. You
                    further agree that your comments will not contain libelous
                    or otherwise unlawful, abusive, or obscene material, or
                    contain any computer virus or other malware that could in
                    any way affect the operation of the Service or any related
                    website. You may not use a false email address, pretend to
                    be someone other than yourself, or otherwise mislead us or
                    third-parties as to the origin of any comments. You are
                    solely responsible for any comments you make and their
                    accuracy. We take no responsibility and assume no liability
                    for any comments posted by you or any third-party.
                  </p>
                  <h5 className="text-orange font-acierdisplay">
                    SECTION 8 - PERSONAL INFORMATION
                  </h5>
                  <p className="font-acierdisplay mb-0 text-white">
                    Your submission of personal information through the website
                    is governed by our Privacy Policy. To view our Privacy
                    Policy, refer to the link provided.
                  </p>
                  <h5 className="text-orange font-acierdisplay">
                    SECTION 9 - ERRORS, INACCURACIES AND OMISSIONS
                  </h5>
                  <p className="font-acierdisplay mb-0 text-white">
                    Occasionally there may be information on our site or in the
                    Service that contains typographical errors, inaccuracies, or
                    omissions that may relate to product descriptions, pricing,
                    promotions, offers, product shipping charges, transit times,
                    and availability. We reserve the right to correct any
                    errors, inaccuracies, or omissions, and to change or update
                    information or cancel orders if any information in the
                    Service or on any related website is inaccurate at any time
                    without prior notice (including after you have submitted
                    your order).
                  </p>
                  <p className="font-acierdisplay mb-0 text-white">
                    We undertake no obligation to update, amend, or clarify
                    information in the Service or on any related website,
                    including without limitation, pricing information, except as
                    required by law. No specified update or refresh date applied
                    in the Service or on any related website should be taken to
                    indicate that all information in the Service or on any
                    related website has been modified or updated.
                  </p>
                  <h5 className="text-orange font-acierdisplay">
                    SECTION 10 - PROHIBITED USES
                  </h5>
                  <p className="font-acierdisplay mb-0 text-white">
                    In addition to other prohibitions as set forth in the Terms
                    of Service, you are prohibited from using the site or its
                    content: (a) for any unlawful purpose; (b) to solicit others
                    to perform or participate in any unlawful acts; (c) to
                    violate any international, federal, provincial, or state
                    regulations, rules, laws, or local ordinances; (d) to
                    infringe upon or violate our intellectual property rights or
                    the intellectual property rights of others; (e) to harass,
                    abuse, insult, harm, defame, slander, disparage, intimidate,
                    or discriminate based on gender, sexual orientation,
                    religion, ethnicity, race, age, national origin, or
                    disability; (f) to submit false or misleading information;
                    (g) to upload or transmit viruses or any other type of
                    malicious code that will or may be used in any way that will
                    affect the functionality or operation of the Service or of
                    any related website, other websites, or the Internet; (h) to
                    collect or track the personal information of others; (i) to
                    spam, phish, pharm, pretext, spider, crawl, or scrape; (j)
                    for any obscene or immoral purpose; or (k) to interfere with
                    or circumvent the security features of the Service or any
                    related website, other websites, or the Internet. We reserve
                    the right to terminate your use of the Service or any
                    related website for violating any of the prohibited uses.
                  </p>
                  <h5 className="text-orange font-acierdisplay">
                    SECTION 11 - DISCLAIMER OF WARRANTIES; LIMITATION OF
                    LIABILITY
                  </h5>
                  <p className="font-acierdisplay mb-0 text-white">
                    We do not guarantee, represent, or warrant that your use of
                    our service will be uninterrupted, timely, secure, or
                    error-free.
                  </p>
                  <p className="font-acierdisplay mb-0 text-white">
                    We do not warrant that the results that may be obtained from
                    the use of the service will be accurate or reliable.
                  </p>
                  <p className="font-acierdisplay mb-0 text-white">
                    You agree that from time to time we may remove the service
                    for indefinite periods of time or cancel the service at any
                    time without notice to you. You expressly agree that your
                    use of or inability to use the service is at your sole risk.
                    The service and all products and services delivered to you
                    through the service are (except as expressly stated by us)
                    provided 'as is' and 'as available' for your use without any
                    representation, warranties, or conditions of any kind,
                    either express or implied, including all implied warranties
                    or conditions of merchantability, merchantable quality,
                    fitness for a particular purpose, durability, title, and
                    non-infringement.
                  </p>
                  <p className="font-acierdisplay mb-0 text-white">
                    In no case shall Foam Productions LLC, Underdawg
                    Entertainment, Unclaimed Freight Productions Inc, or their
                    directors, officers, employees, affiliates, agents,
                    contractors, interns, suppliers, service providers, or
                    licensors be liable for any injury, loss, or claim, or any
                    direct, indirect, incidental, punitive, special, or
                    consequential damages of any kind, including without
                    limitation lost profits, lost revenue, lost savings, loss of
                    data, replacement costs, or any similar damages, whether
                    based in contract, tort (including negligence), strict
                    liability, or otherwise, arising from your use of any of the
                    service or any products procured using the service, or for
                    any other claim related in any way to your use of the
                    service or any product, including, but not limited to, any
                    errors or omissions in any content, or any loss or damage of
                    any kind incurred as a result of the use of the service or
                    any content (or product) posted, transmitted, or otherwise
                    made available via the service, even if advised of their
                    possibility. Because some states or jurisdictions do not
                    allow the exclusion or the limitation of liability for
                    consequential or incidental damages, in such states or
                    jurisdictions, our liability shall be limited to the maximum
                    extent permitted by law.
                  </p>
                  <h5 className="text-orange font-acierdisplay">
                    SECTION 12 - INDEMNIFICATION
                  </h5>
                  <p className="font-acierdisplay mb-0 text-white">
                    You agree to indemnify, defend, and hold harmless Foam
                    Productions, LLC, Unclaimed Freight Productions Inc., Coffee
                    Wars and our parent, subsidiaries, affiliates, partners,
                    officers, directors, agents, contractors, licensors, service
                    providers, subcontractors, suppliers, interns, and employees
                    from any claim or demand, including reasonable attorneys’
                    fees, made by any third-party due to or arising out of your
                    breach of these Terms of Service or the documents they
                    incorporate by reference, or your violation of any law or
                    the rights of a third-party.
                  </p>
                  <h5 className="text-orange font-acierdisplay">
                    SECTION 13 - SEVERABILITY
                  </h5>
                  <p className="font-acierdisplay mb-0 text-white">
                    In the event that any provision of these Terms of Service is
                    determined to be unlawful, void, or unenforceable, such
                    provision shall nonetheless be enforceable to the fullest
                    extent permitted by applicable law, and the unenforceable
                    portion shall be deemed to be severed from these Terms of
                    Service, and such determination shall not affect the
                    validity and enforceability of any other remaining
                    provisions.
                  </p>
                  <h5 className="text-orange font-acierdisplay">
                    SECTION 14 - TERMINATION
                  </h5>
                  <p className="font-acierdisplay mb-0 text-white">
                    The obligations and liabilities of the parties incurred
                    prior to the termination date shall survive the termination
                    of this agreement for all purposes.
                  </p>
                  <p className="font-acierdisplay mb-0 text-white">
                    These Terms of Service are effective unless and until
                    terminated by either you or us. You may terminate these
                    Terms of Service at any time by notifying us that you no
                    longer wish to use our Services or when you cease using our
                    site. If, in our sole judgment, you fail, or we suspect that
                    you have failed, to comply with any term or provision of
                    these Terms of Service, we also may terminate this agreement
                    at any time without notice, and you will remain liable for
                    all amounts due up to and including the date of termination;
                    and/or accordingly we may deny you access to our Services
                    (or any part thereof).
                  </p>
                  <h5 className="text-orange font-acierdisplay">
                    SECTION 15 - ENTIRE AGREEMENT
                  </h5>
                  <p className="font-acierdisplay mb-0 text-white">
                    Any failure on our part to exercise or enforce any right or
                    provision of these Terms of Service shall not constitute a
                    waiver of such right or provision. These Terms of Service
                    and any policies or operating rules posted by us on this
                    site or in respect to The Service constitute the entire
                    agreement and understanding between you and us and govern
                    your use of the Service, superseding any prior or
                    contemporaneous agreements, communications, and proposals,
                    whether oral or written, between you and us (including, but
                    not limited to, any prior versions of the Terms of Service).
                    Any ambiguities in the interpretation of these Terms of
                    Service shall not be construed against the drafting party.
                  </p>
                  <h5 className="text-orange font-acierdisplay">
                    SECTION 16 - GOVERNING LAW
                  </h5>
                  <p className="font-acierdisplay mb-0 text-white">
                    These Terms of Service and any separate agreements whereby
                    we provide you Services shall be governed by and construed
                    in accordance with the laws of California. Foam Productions
                    LLC is located in Pasadena, California.
                  </p>
                  <h5 className="text-orange font-acierdisplay">
                    SECTION 17 - CHANGES TO TERMS OF SERVICE
                  </h5>
                  <p className="font-acierdisplay mb-0 text-white">
                    You can review the most current version of the Terms of
                    Service at any time at this page.
                  </p>
                  <p className="font-acierdisplay mb-0 text-white">
                    We reserve the right, at our sole discretion, to update,
                    change, or replace any part of these Terms of Service by
                    posting updates and changes to our website. It is your
                    responsibility to check our website periodically for
                    changes. Your continued use of or access to our website or
                    the Service following the posting of any changes to these
                    Terms of Service constitutes acceptance of those changes.
                  </p>
                  <h5 className="text-orange font-acierdisplay">
                    SECTION 18 - CONTACT INFORMATION
                  </h5>
                  <p className="font-acierdisplay mb-0 text-white">
                    Questions about the Terms of Service should be sent to us at
                    uncindie@gmail.com
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>

          <Col className="row align-items-center mt-5">
            <Col className="col-lg-4 d-flex">
              <h5 className="mb-0 text-white fs-6 font-acierdisplay letterspace1px">
                Connect With Us On Discord:
                <a href="https://discord.gg/KDF5HKa3mb" target="_blank">
                  <DiscordIcon
                    className={classNames(styles["discord-icon"], "ms-2")}
                  />
                </a>
              </h5>
            </Col>
            <Col className="col-lg-4">
              <a
                className="d-inline-block text-decoration-none fs-6 font-acierdisplay letterspace1px text-white"
                href="#"
              >
                Terms of Service
              </a>
            </Col>
            <Col className="col-lg-4 text-decoration-none fs-6 font-acierdisplay letterspace1px text-white">
              © 2022 Foam Productions LLC
            </Col>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default FaqPage;
