import classNames from "classnames/bind";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./Banner.module.scss";

const Banner = () => {
  return (
    <Container>
      <Row
        className={classNames(
          styles["container"],
          "px-md-0 px-3",
          "align-items-end flex-xl-row flex-column-reverse"
        )}
      >
        <Col
          className={classNames(
            styles["left-container"],
            "col-xl-7 col-12 ps-0 pe-lg-5"
          )}
        >
          <div className="mb-3 mb-xl-4">
            <span
              className={classNames(styles["coffee-wars"], "text-lightred")}
            >
              COFFEE WARS
            </span>{" "}
            is a comedy about changing the world.
          </div>
          <div className="mb-3">
            The film is financed by VegGood Films, committed vegan activists who
            care about the world’s people, our animals, and the health of the
            planet.
          </div>
          <div>
            <span className={classNames(styles["green"], "text-lightgreen")}>
              100%{" "}
            </span>{" "}
            OF ALL THE REVENUE IS GOING TO CHARITY!
          </div>
        </Col>
        <Col className="col-xl-5 col-12 px-0 mb-4 mb-xl-0 animate__animated animate__fadeInRight">
          <a
            className={classNames("d-inline-block text-white text-decoration-none", styles["banner-img"])}
            target="_blank"
            href="https://www.youtube.com/watch?v=xfM7oszztI0"
          >
            <img src="/images/banner.png" className="img-fluid" alt="banner.png" />
            <div className="text-uppercase font-acierdisplay">Click to see trailer</div>
          </a>
        </Col>
      </Row>
    </Container>
  );
};

export default Banner;
