import classNames from "classnames/bind";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./GetWaysSection.module.scss";

const GetWaysSection = () => {
  return (
    <Container>
      <Row
        className={classNames(
          styles["container"],
          "px-md-0 px-3",
          "align-items-end justify-content-between"
        )}
      >
        <Col className={classNames(styles["title"], "col-12 px-3 py-5 box-shadow")}>
          2 WAYS TO GET YOU INVOLVED!
        </Col>
        <Col
          className={classNames(
            "col-xl-4 col-12 px-3 mb-5 mb-xl-0 py-2 animate__animated animate__fadeInLeft",
            styles["left-box"]
          )}
        >
          <Row
            className={classNames(
              styles["content"],
              "p-0 m-0 px-3 py-3 w-100 h-100 d-flex align-items-center justify-center"
            )}
          >
            <Col className="col-6 p-0">
              <h1 className={classNames(styles["number"], "box-shadow")}>1.</h1>
              <h3 className={classNames(styles["title"], "box-shadow")}>
                SINGLE SHOT <br /> BUZZY LEVEL
              </h3>
              <div className={classNames(styles["description"], "box-shadow")}>
                Buy one of our <span className={styles["yellow"]}>10,000</span>{" "}
                artistically handcrafted and completely unique character tokens.
              </div>
            </Col>

            <Col className="col-6 p-0">
              <img src="/images/buzzy_1.png" className="animate__animated animate__shakeX animate__infinite" alt="buzzy_1.png" />
            </Col>

            <img
              className={classNames(styles["bg-img"], "p-0")}
              src="/images/getways/bg_1.png"
              alt="img 1"
            />
          </Row>
        </Col>

        <Col
          className={classNames(
            "col-xl-7 col-12 px-3 py-2 animate__animated animate__fadeInRight",
            styles["right-box"]
          )}
        >
          <Row
            className={classNames(
              styles["content"],
              "px-3 py-2 m-0 p-0 w-100 h-100 align-items-stretch justify-content-center"
            )}
          >
            <img
              className={classNames(styles["bg-img"], "p-0")}
              src="/images/getways/bg_2.png"
              alt="img 1"
            />

            <h1 className={classNames(styles["number"], "box-shadow")}>2.</h1>
            <Col className=" col-xl-5 d-flex flex-column justify-content-center align-items-center">
              <img
                className={classNames(
                  styles["doubleshot"],
                  "animate__animated animate__shakeX animate__infinite mb-2"
                )}
                src="/images/doubleshot.png"
                alt="doubleshot.png"
              />

              <h3 className={classNames(styles["title"], "box-shadow")}>DOUBLE SHOT <br /> BUZZMAKER  <br /> LEVEL</h3>
              <div className={classNames(styles["description"], "box-shadow")}>               
Buy one of the <span className={styles["yellow"]}>100</span> and you get your picture somewhere in the sequel. 
Yep, you heard that right.
              </div>
            </Col>

            <Col className="col-xl-7 d-flex flex-column justify-content-between">
              <h1 className={classNames(styles["text"], "text-lightyellow mt-3 box-shadow")}>
                Check out this Random Dude from the internet as an example...
              </h1>

              <img className={classNames("mb-xl-4", styles["people-img"])} src="/images/people_1.jpg" alt="people" />
              <img className={classNames("mb-xl-4 animate__animated animate__shakeY animate__infinite d-none d-lg-block", styles["arrow-img"])} src="/images/arrow.png" alt="arrow" />
              <img className="mb-xl-4" src="/images/getways/bg_3.png" alt="img 3" />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default GetWaysSection;
