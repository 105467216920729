const singleShots = [
  {
    url: "https://cbgb.mypinata.cloud/ipfs/QmdykitnkRLnNF23K8v9mfnAxHHH2QTq5H3G2dFbRcLnsx/Jo-Scroll-Video-070422.gif",
    title: "JO: Our hero",
    desc: "Played by Kate Nash",
  },
  {
    url: "https://cbgb.mypinata.cloud/ipfs/QmdykitnkRLnNF23K8v9mfnAxHHH2QTq5H3G2dFbRcLnsx/Roopa-Scroll-Video-070422.gif",
    title: "ROOPA: Her kooky sidekick",
    desc: "Played by Saoirse-Monica Jackson",
  },
  {
    url: "https://cbgb.mypinata.cloud/ipfs/QmdykitnkRLnNF23K8v9mfnAxHHH2QTq5H3G2dFbRcLnsx/Rudy-Scroll-Video-070422.gif",
    title: "RUDY: The villian",
    desc: "Played by Toby Sebastian",
  },
  {
    url: "https://cbgb.mypinata.cloud/ipfs/QmdykitnkRLnNF23K8v9mfnAxHHH2QTq5H3G2dFbRcLnsx/Andy-Scroll-Video-070422.gif",
    title: "ANDY: Her master roaster",
    desc: "Played by Owain Arthur",
  },
  {
    url: "https://cbgb.mypinata.cloud/ipfs/QmdykitnkRLnNF23K8v9mfnAxHHH2QTq5H3G2dFbRcLnsx/Maja-Scroll-Video-070422.gif",
    title: "MAJA: Her junior barista",
    desc: "Played by Maya Miller",
  },
  {
    url: "https://cbgb.mypinata.cloud/ipfs/QmdykitnkRLnNF23K8v9mfnAxHHH2QTq5H3G2dFbRcLnsx/Hans-Scroll-Video-070422.gif",
    title: "HANS: Her old flame",
    desc: "Played by Freddie Fox",
  },
  {
    url: "https://cbgb.mypinata.cloud/ipfs/QmdykitnkRLnNF23K8v9mfnAxHHH2QTq5H3G2dFbRcLnsx/Ray-Scroll-Video-070422.gif",
    title: "RAY: Her current flame",
    desc: "Played by Jordan Stephens",
  },
  {
    url: "https://cbgb.mypinata.cloud/ipfs/QmdykitnkRLnNF23K8v9mfnAxHHH2QTq5H3G2dFbRcLnsx/Stoney-Scroll-Video-070422.gif",
    title: "STONEY: Her 3rd best friend",
    desc: "Played by Jenny Rainsford",
  },
  {
    url: "https://cbgb.mypinata.cloud/ipfs/QmdykitnkRLnNF23K8v9mfnAxHHH2QTq5H3G2dFbRcLnsx/Lisa-Scroll-Video-070422.gif",
    title: "LISA: Sex-crazied TV Partner",
    desc: "Played by Sally Phillips",
  },
  {
    url: "https://cbgb.mypinata.cloud/ipfs/QmdykitnkRLnNF23K8v9mfnAxHHH2QTq5H3G2dFbRcLnsx/Jorge-Scroll-Video-070422.gif",
    title: "JORGE: Head barista judge",
    desc: "Played by Tobias Forrest",
  },
  {
    url: "https://cbgb.mypinata.cloud/ipfs/QmdykitnkRLnNF23K8v9mfnAxHHH2QTq5H3G2dFbRcLnsx/Bernie-Scroll-Video-070422.gif",
    title: "BERNIE: World Barista commentator",
    desc: "Played by Ray Fearon",
  },
  {
    url: "https://cbgb.mypinata.cloud/ipfs/QmdykitnkRLnNF23K8v9mfnAxHHH2QTq5H3G2dFbRcLnsx/Isabella-Scroll-Video-070422.gif",
    title: "ISABELLA: Her master grower",
    desc: "Played by Maria Conchita Alonso",
  },
  {
    url: "https://cbgb.mypinata.cloud/ipfs/QmdykitnkRLnNF23K8v9mfnAxHHH2QTq5H3G2dFbRcLnsx/Mum-Scroll-Video-070422.gif",
    title: "MUM: Her loving mother",
    desc: "Played by Rosie Cavaliero",
  },
  {
    url: "https://cbgb.mypinata.cloud/ipfs/QmdykitnkRLnNF23K8v9mfnAxHHH2QTq5H3G2dFbRcLnsx/Dad-Scroll-Video-070422.gif",
    title: "DAD: Her loving father",
    desc: "Played by Hugh Dennis",
  },
  {
    url: "https://cbgb.mypinata.cloud/ipfs/QmdykitnkRLnNF23K8v9mfnAxHHH2QTq5H3G2dFbRcLnsx/Bridget-Scroll-Video-070422.gif",
    title: "BRIDGET: Her frequent customer",
    desc: "Played by Lydia West",
  },
  {
    url: "https://cbgb.mypinata.cloud/ipfs/QmdykitnkRLnNF23K8v9mfnAxHHH2QTq5H3G2dFbRcLnsx/Group-Scroll-Video-070422.gif",
    title: "All For One",
    desc: "Played by Team",
  },
];

export { singleShots };
