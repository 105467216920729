import React from "react";
import { Container } from "react-bootstrap";
import Banner from "../../Components/Banner/Banner";
import GetWaysSection from "../../Components/GetWaysSection/GetWaysSection";
import Header from "../../Components/Header/Header";
import ShotSection from "../../Components/ShotSection/ShotSection";
import styles from "./Home.module.scss";

const Home = () => {
  return (
    <div className={styles["container"]}>
      <Header />
      <Container fluid>
        <Banner />
        <GetWaysSection />
        <ShotSection />
      </Container>
    </div>
  );
};

export default Home;
