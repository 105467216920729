import { Container, Nav, Navbar, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "./Header.module.scss";
import classNames from "classnames/bind";
import { Switch, Case, Default, If, Then, Else } from 'react-if';

const MENU_ITEMS = [{
        name: `home`,
        to: `/`,
    },
    {
        name: `roadmap`,
        to: `/roadmap`,
    },
    {
        name: `manifesto`,
        to: `/mainifesto`,
    },
    {
        name: `facts`,
        to: `/faq`,
    },
    {
        name: `team`,
        to: `/team`,
    },
    {
        name: `crip`,
        to: `/crip-token`,
    },
];
const Header = () => {
    return (
        <Container className={classNames(styles["container"])}>
      <Row className="px-md-0 px-3 py-3">
        <Navbar
          expand="lg"
          className={classNames(styles["header-navbar"], "justify-content-end")}
        >
          <Navbar.Brand
            className={classNames(
              styles["logo"],
              "position-absolute d-none d-xl-block p-0"
            )}
          >
            <a
              target="_blank"
              className="d-inline-block"
              href="https://www.youtube.com/watch?v=xfM7oszztI0"
            >
              <img src="/logo.png" alt="logo" />
            </a>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="text-uppercase w-100 align-items-center justify-content-end gap-lg-5 gap-3">
              {MENU_ITEMS.map((el, index) => (
              <If condition={el.href}>
                    <Then>
                    <a key={`menu-link-${index}`}
                      href={el.href}
                      target="_blank"
                      className="text-white text-decoration-none lh-1">{el.name}</a>
                    </Then>
                    <Else>
                      <Link
                      key={`menu-link-${index}`}
                      to={el.to}
                      className="text-white text-decoration-none lh-1"
                    >
                      {el.name}
                    </Link>
                    </Else>
                  </If> 
              ))}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Row>
    </Container>
    );
};

export default Header;