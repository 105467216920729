import classNames from "classnames/bind";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./ShotSection.module.scss";
import { singleShots } from "../../config/shots";

function ShotSection() {
  return (
    <Container className={classNames(styles["container"], "pt-6 pb-6 px-3 px-md-5")} id="quadshot">
      <Row className="justify-content-center">
        <Col
          sm={12}
          className={classNames(
            styles["heading"],
            "text-start text-white text-center position-relative d-flex flex-xl-row flex-column gap-2 gap-xxl-5 align-items-center"
          )}
        >
          <h1 className={classNames(styles["title"], "box-shadow")}>OUR BARISTAS</h1>
          <div className="text-center">
            <h5 className="m-0">EXAMPLE OF THE DIGITAL COLLECTION</h5>
            <div>(Hover over to VIEW various available)</div>
          </div>
        </Col>

        {singleShots.map((e, index) => (
          <Col
            key={`single-shot-${index}`}
            className="col-md-6 col-lg-4 col-12 mt-5 animate__animated animate__fadeInLeft wow animated_duration2s"
          >
            <Col className="w-100 text-center">
              <Col
                className="nft-video w-90 mb-2 mx-auto"
                style={{ boxShadow: "1px 1px 12px 1px #f98425" }}
              >
                <img src={e.url} alt={e.title} className="mx-auto" />
              </Col>
              <h5 className={classNames(styles["item-title"], "text-white 1h-1 m-0 font-blenny letterspace1px")}>
                {e.title}
              </h5>
              <div className="text-orange px-3 font-acierdisplay fontsize20px">
                {e.desc}
              </div>
            </Col>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default ShotSection;
