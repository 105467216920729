import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import 'animate.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.scss";
import Home from "./pages/Home/Home";
import Roadmap from "./pages/Roadmap/Roadmap";
import Manifesto from "./pages/Manifesto/Manifesto";
import GoodTokenPage from "./pages/GoodTokenPage/GoodTokenPage";
import FaqPage from "./pages/FaqPage/FaqPage";
import TeamPage from "./pages/TeamPage/TeamPage";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="*" element={<Home />} />
          <Route path="/roadmap" element={<Roadmap />} />
          <Route path="/mainifesto" element={<Manifesto />} />
          <Route path="/crip-token" element={<GoodTokenPage />} />
          <Route path="/faq" element={<FaqPage />} />
          <Route path="/team" element={<TeamPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
