import classNames from "classnames";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../../Components/Header/Header";
import styles from "./Manifesto.module.scss";

function Manifesto() {
  return (
    <div className={styles["container"]}>
      <Header />
      <Container className={styles["main"]}>
        <Row className="px-md-0 px-3">
          <Col sm={12} className="position-relative text-center">
            <h1
              className={classNames(styles["title"], "font-blenny text-white box-shadow")}
            >
              Our Manifesto
            </h1>
          </Col>

          <Col
            sm={12}
            className={classNames("text-center", styles["text-sub"])}
          >
            <h3 className="text-uppercase text-lightyellow font-acierdisplay letterspace5px">
              PURE And SIMPLE
            </h3>
          </Col>

          <Col
            sm={12}
            className={classNames(
              "text-center text-white font-barricada mt-7",
              styles["description"]
            )}
          >
            The only way to make lasting change is take on the fight and make it
            happen... don’t wait for someone else to say you can. At{" "}
            <span
              className={classNames(styles["coffee-wars"], "text-orange-100")}
            >
              COFFEE WARS
            </span>{" "}
            we believe that storytellers and artists deserve an outlet where
            they can be valued and supported without having to bow to the
            machine of big media. So we’re tilting the model on its head and
            testing a new architecture using NFTs to connect storytellers
            directly with their audience to essentially decentralize content
            production. And moreover we want to share the rewards directly with
            those that support us...
          </Col>

          <Col
            sm={9}
            className={classNames(
              "text-center text-white font-barricada mt-5 mx-auto",
              styles["description"]
            )}
          >
            We all need to do our part to curb global warming. This is the
            message of{" "}
            <span
              className={classNames(styles["coffee-wars"], "text-orange-100")}
            >
              COFFEE WARS
            </span>
            ... But we believe, why not have some fun doing it?
          </Col>

          <Col sm={12} className="px-5 mt-6">
            <img
              src="/images/manifesto.png"
              className="rounded-1"
              alt="manifesto.png"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Manifesto;
