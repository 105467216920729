import classNames from "classnames/bind";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../../Components/Header/Header";
import styles from "./Roadmap.module.scss";

function Roadmap() {
  return (
    <div className={styles["container"]}>
      <Header />
      <Container className={styles["main"]}>
        <Row className="px-lg-0 px-5">
          <Col sm={12} className="position-relative text-center">
            <h1
              className={classNames(
                styles["title"],
                "font-blenny text-white letterspace12px box-shadow"
              )}
            >
              RoadMap
            </h1>
          </Col>

          <Col
            sm={12}
            className={classNames("text-center", styles["text-sub"])}
          >
            <h3 className="text-uppercase text-lightyellow font-acierdisplay letterspace5px">
              The Path From Start To Finish
            </h3>
          </Col>

          <Row className="text-start p-0 m-0">
            <Col className="mt-4 col-lg-4 col-12">
              <Col className="w-100">
                <h5
                  className={classNames(
                    styles["date"],
                    "text-white font-acierdisplay"
                  )}
                >
                  MARCH 2023
                </h5>
                <h5 className="text-orange fw-bold m-0 fontsize20px">
                  - COFFEE WARS released.
                </h5>
              </Col>
              <Col className="w-100 mt-4 animate__animated animate__fadeInLeft wow animated_duration2s">
                <img
                  src="images/map1.jpg"
                  alt="images/map1.jpg"
                  className="img-fluid w-100"
                  style={{
                    filter: "drop-shadow(0.052px 0.999px 17.5px #040300)",
                  }}
                />
              </Col>
            </Col>

            <Col className="mt-4 col-lg-3 d-none d-lg-block">
              <Col>
                <img
                  src="images/arrow.png"
                  alt="arrow"
                  className={classNames(
                    styles["arrow"],
                    "img-fluid animate__animated animate__shakeY animate__infinite"
                  )}
                />
              </Col>
            </Col>

            <Col className="col-lg-5 col-12 mt-4 d-flex align-items-end text-start">
              <Col className="w-100">
                <h5
                  className={classNames(
                    styles["date"],
                    "text-white font-acierdisplay"
                  )}
                >
                  May 2023
                </h5>
                <ul className="text-orange font-bold list-unstyled p-0 w-bold m-0 fontsize20px">
                  <li>- High fives on the success of COFFEE WARS. </li>
                  <li>
                    - Hosting of monthly Discord chat with filmmakers and
                    behind-the-scenes crew.
                  </li>
                </ul>
              </Col>
            </Col>
          </Row>

          {/*  */}
          <Row className="text-start p-0 m-0 mb-5 flex-lg-row flex-column-reverse">
            <Col className="col-12 col-lg-4 text-start mt-4 mt-lg-0">
              <Col className="w-100">
                <h5
                  className={classNames(
                    styles["date"],
                    "text-white font-acierdisplay"
                  )}
                >
                  July 2023
                </h5>
                <ul className="text-orange font-bold list-unstyled p-0 w-bold m-0 fontsize20px">
                  <li>- Publicity for the worldwide release of COFFEE WARS </li>
                  <li>- Screenplay and development begins on the sequel. </li>
                </ul>
              </Col>
            </Col>
            <Col
              style={{ transform: `translateY(50%)` }}
              className="text-start d-none d-lg-block col-lg-3"
            >
              <img
                src="images/arrow.png"
                alt="arrow"
                className={classNames(
                  styles["arrow"],
                  "img-fluid animate__animated animate__shakeY animate__infinite"
                )}
              />
            </Col>
            <Col sm={1} className="d-none d-lg-block" />
            <Col className="col-12 col-lg-4 justify-content-end">
              <Col
                className="w-100 mt-lg-4 animate__animated animate__fadeInRight wow animated_duration2s"
              >
                <img
                  src="images/map2.jpg"
                  alt="images/map2.jpg"
                  className="img-fluid w-100"
                  style={{
                    filter: "drop-shadow(0.052px 0.999px 17.5px #040300)",
                  }}
                />
              </Col>
            </Col>
          </Row>
          {/*  */}

          <Row className="p-0 m-0 mb-5">
            <Col className="col-lg-4 col-12">
              <Col className="w-100 animate__animated animate__fadeInLeft wow animated_duration2s">
                <img
                  src="images/map3.jpg"
                  alt="images/map3.jpg"
                  className="img-fluid w-100"
                  style={{
                    filter: "drop-shadow(0.052px 0.999px 17.5px #040300)",
                  }}
                />
              </Col>
            </Col>

            <Col sm={3} className="mt-auto d-none d-lg-block" />

            <Col className="col-lg-5 col-12 mt-lg-auto mt-4 mb-auto">
              <Col className="w-100 text-start">
                <h5
                  className={classNames(
                    styles["date"],
                    "text-white font-acierdisplay"
                  )}
                >
                  SEPTEMBER 2023
                </h5>
                <ul className="text-orange font-bold list-unstyled p-0 w-bold m-0 fontsize20px">
                  <li>- Launch Coffe Wars NFT.</li>
                  <li>- Coffee Wars Released on Amazon Prime.</li>
                </ul>
              </Col>
            </Col>
          </Row>

          {/*  */}
          <Row className="m-0 p-0 text-start mb-5 flex-lg-row flex-column-reverse">
            <Col className="col-lg-4 col-12">
              <Col className="w-100 mt-4 mt-lg-0">
                <h5
                  className={classNames(
                    styles["date"],
                    "text-white font-acierdisplay"
                  )}
                >
                  FEBRUARY 2024
                </h5>
                <ul className="text-orange list-unstyled p-0 m-0 fontsize20px">
                  <li>- Pre-Production begins on the COFFEE WARS sequel.</li>
                </ul>
              </Col>
            </Col>
            <Col className="col-3 d-none d-lg-block">
              <Col className="pt-5">
                <img
                  src="images/arrow.png"
                  alt="arrow"
                  className={classNames(
                    styles["arrow"],
                    "img-fluid animate__animated animate__shakeY animate__infinite"
                  )}
                />
              </Col>
            </Col>
            <Col sm={1} className="d-none d-lg-block" />
            <Col className="mt-lg-4 col-lg-4 col-12">
              <Col className="w-100 animate__animated animate__fadeInRight wow animated_duration2s">
                <img
                  src="images/map4.jpg"
                  alt="images/map4.jpg"
                  className="img-fluid w-100"
                  style={{
                    filter: "drop-shadow(0.052px 0.999px 17.5px #040300)",
                  }}
                />
              </Col>
            </Col>
          </Row>
          {/*  */}

          {/*  */}
          <Row sm={12} className="m-0 p-0 mb-5">
            <Col className="col-lg-4 col-12">
              <Col className="w-100 animate__animated animate__fadeInLeft wow animated_duration2s">
                <img
                  src="images/map5.jpg"
                  alt="images/map5.jpg"
                  className="img-fluid w-100"
                  style={{
                    filter: "drop-shadow(0.052px 0.999px 17.5px #040300)",
                  }}
                />
              </Col>
            </Col>
            <Col sm={3} className="mt-auto d-none d-lg-block"></Col>
            <Col className="mt-auto mb-auto text-start col-lg-5 col-12">
              <Col className="w-100 mt-4 mt-lg-0">
                <h5
                  className={classNames(
                    styles["date"],
                    "text-white font-acierdisplay"
                  )}
                >
                  MAY 2024
                </h5>
                <ul className="text-orange list-unstyled p-0 m-0 fontsize20px">
                  <li>- Casting begins on the sequel. </li>
                </ul>
              </Col>
            </Col>
          </Row>
          {/*  */}

          {/*  */}
          <Row className="m-0 p-0 text-start mb-5 flex-lg-row flex-column-reverse">
            <Col className="mt-4 mt-lg-0 col-lg-4 col-12">
              <Col className="w-100 mt-3">
                <h5
                  className={classNames(
                    styles["date"],
                    "text-white font-acierdisplay"
                  )}
                >
                  August 2023
                </h5>
                <ul className="text-orange fontsize20px list-unstyled p-0 m-0">
                  <li>- Financing finalized on the sequel. </li>
                </ul>
              </Col>
            </Col>
            <Col sm={4} className="mt-auto d-none d-lg-block"></Col>
            <Col className="mt-lg-4 col-12 col-lg-4">
              <Col className="w-100 animate__animated animate__fadeInRight wow animated_duration2s">
                <img
                  src="images/map6.jpg"
                  alt="images/map6.jpg"
                  className="img-fluid w-100"
                  style={{
                    filter: "drop-shadow(0.052px 0.999px 17.5px #040300)",
                  }}
                />
              </Col>
            </Col>
          </Row>
          {/*  */}

          {/*  */}
          <Row sm={12} className="m-0 p-0 mb-5 text-start">
            <Col className="col-lg-4 col-12">
              <Col className="w-100 animate__animated animate__fadeInLeft wow animated_duration2s">
                <img
                  src="images/map7.jpg"
                  alt="images/map7.jpg"
                  className="img-fluid w-100"
                  style={{
                    filter: "drop-shadow(0.052px 0.999px 17.5px #040300)",
                  }}
                />
              </Col>
            </Col>
            <Col sm={3} className="mt-auto d-none d-lg-block"></Col>
            <Col className="mt-auto mb-auto col-lg-5 col-12">
              <Col className="w-100 mt-4 mt-lg-0">
                <h5
                  className={classNames(
                    styles["date"],
                    "text-white font-acierdisplay"
                  )}
                >
                  November 2024
                </h5>
                <ul className="text-orange fontsize20px list-unstyled p-0 m-0">
                  <li>- Production begins on the sequel. </li>
                </ul>
              </Col>
            </Col>
          </Row>
          {/*  */}

          {/* Spring */}
          <Row className="m-0 p-0 text-start mb-5 flex-lg-row flex-column-reverse">
            <Col className="mt-4 mt-lg-0 col-12 col-lg-4">
              <Col className="w-100">
                <h5
                  className={classNames(
                    styles["date"],
                    "text-white font-acierdisplay"
                  )}
                >
                  Summer 2025
                </h5>
                <ul className="text-orange list-unstyled p-0 m-0 fontsize20px">
                  <li>- The COFFEE WARS sequel will be released. </li>
                  <li>- Exclusive Q&A with filmmakers. </li>
                </ul>
              </Col>
            </Col>
            <Col
              style={{ transform: `translateY(-60%)` }}
              className="ps-4 d-lg-block d-none col-3"
            >
              <img
                src="images/arrow.png"
                alt="arrow"
                className={classNames(
                  styles["arrow"],
                  "img-fluid animate__animated animate__shakeY animate__infinite"
                )}
              />
            </Col>
            <Col className="d-none d-lg-block col-1" />
            <Col className="mt-lg-4 mt-lg-0 col-lg-4 col-12">
              <Col className="w-100 animate__animated animate__fadeInRight wow animated_duration2s">
                <img
                  src="images/map8.jpg"
                  alt="images/map8.jpg"
                  className="img-fluid w-100"
                  style={{
                    filter: "drop-shadow(0.052px 0.999px 17.5px #040300)",
                  }}
                />
              </Col>
            </Col>
          </Row>
          {/* Spring */}
        </Row>
      </Container>
    </div>
  );
}

export default Roadmap;
