import classNames from "classnames";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../../Components/Header/Header";
import styles from "./GoodTokenPage.module.scss";





function GoodTokenPage() {
    return (
        <div className={styles["container"]}>
      <Header />
      <Container className={styles["main"]}>
        <Row className="px-md-0 px-3">
          <Col sm={12} className="position-relative text-center">
            <h1
              className={classNames(styles["title"], "font-blenny text-white box-shadow")}
            >
              CRIP
            </h1>
          </Col>

        



          <Col
            sm={12}
            className={classNames("text-center", styles["text-sub"])}
          >
            <h3 className="text-uppercase text-yellow-100 font-acierdisplay letterspace2px">
              A CRIPto currency!
            </h3>
          </Col>

          <Col
            className={classNames(
              "col-12 col-lg-10 mx-auto text-center text-white font-barricada mt-5",
              styles["description"]
            )}
          >
          We have created a crypto currency that will be <span className="text-orange-500">open sourced</span>.
{" "}
          </Col>
          <Col
            className={classNames(
              "col-12 col-lg-10 mx-auto text-center text-white font-barricada mt-4",
              styles["description"]
            )}
          >
            Meaning-- it will be traded freely on the open market.
          </Col>

          <Col
            className={classNames(
              "col-12 col-lg-10 mx-auto text-center text-white font-barricada mt-4",
              styles["description"]
            )}
          >
            As the Team members we will be buying it from the same pool as anyone else.
          </Col>
          <Col
            className={classNames(
              "col-12 col-lg-10 mx-auto text-center text-white font-barricada mt-4",
              styles["description"]
            )}
          >
            We have created <span className="text-lightyellow">550 million</span> tokens.
          </Col>

          <Col
            className={classNames(
              "col-12 col-lg-10 mx-auto text-center text-white font-barricada mt-4",
              styles["description"]
            )}
          >
            The token-omics are as follows:{" "}
          </Col>
          <Col
            className={classNames(
              "col-12 col-lg-10 mx-auto text-center text-white font-barricada mt-4",
              styles["description"]
            )}
          >
            No <span className="text-lightyellow">Taxes</span>, No <span className="text-orange-500"> Bullshit</span>. It’s that <span className="text-lightyellow">simple</span>.
       </Col>
       <Col
            className={classNames(
              "col-12 col-lg-10 mx-auto text-center text-white font-barricada mt-4",
              styles["description"]
            )}
          >
          <span className="text-lightyellow">95%</span> of the tokens were sent to the liquidity pool, 
          LP tokens were burnt, and contract is renounced. 
      </Col>
       <Col
            className={classNames(
              "col-12 col-lg-10 mx-auto text-center text-white font-barricada mt-4",
              styles["description"]
            )}
          > 
          The remaining <span className="text-lightyellow">5%</span> of the supply is being held in a 
          multi-sig wallet only to be used as tokens for future centralized exchange 
          listings, bridges, and liquidity pools.
      </Col>
       <Col
            className={classNames(
              "col-12 col-lg-10 mx-auto text-center text-white font-barricada mt-4",
              styles["description"]
            )}
          >
          This wallet will be easily trackable.
          </Col>
          <Col className="col-12 d-flex justify-content-center mt-6">
            <Col className="col-3 animate__animated animate__bounce animate__infinite">
              <img src="/images/crip_token.png" alt="crip_token.png" />
            </Col>
          </Col>
        </Row>
      </Container>
    </div>
    );
}

export default GoodTokenPage;