import classNames from "classnames";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../../Components/Header/Header";
import styles from "./TeamPage.module.scss";

const TEAMS = [
  {
    imgSrc: `/images/team_1.png`,
    name: "RANDALL MILLER",
    position: "Founder",
    to: `https://www.imdb.com/name/nm0589168/`,
    buttonText: "IMDb",
    description: [
      `Writer/Director Randall Miller is the director of CBGB, 
    Bottle Shock, Coffee Wars, Nobel Son Houseguest, The 6th Man, and Class Act.`,
      `Check him out here:`,
    ],
  },
  {
    imgSrc: `/images/team_2.png`,
    name: "JODY SAVIN",
    position: "Producer",
    buttonText: "IMDb",
    to: `https://www.imdb.com/name/nm0767713/?ref_=fn_al_nm_1`,
    description: [
      `Writer/Producer Jody Savin is the producer of CBGB, Bottle Shock, 
    Coffee Wars, Nobel Son Houseguest, Savannah, and`,
      `The 6th Man.`, `Check her out here:`,
    ],
  },
  {
    imgSrc: `/images/team_3.png`,
    name: "MICHAEL DAVIS",
    position: "Artist",
    buttonText: "IMDb",
    to: `https://www.imdb.com/name/nm0205157/?ref_=fn_al_nm_2`,
    description: [
      `Writer/Director Michael Davis is the director of Shoot 'Em Up, Nixed,
    100 Girls, Monster Man, Girl Fever, 
    and 8 Days A Week.`,
      `Check him out here:`,
    ],
  },
  {
    imgSrc: `/images/team_4.png`,
    name: "Steve McKEON",
    position: "Head Nerd",
    buttonText: "MACNERD",
    to: "https://macnerd.io",
    description: [
      `Tech Wizard Steve McKeon is the man behind the curtain who makes sure 
    that it all works. Steve runs MacNerd Enterprises and MacGuyver Tech.`,
      `Check him out here:`,
    ],
  },
];
function TeamPage() {
  return (
    <div className={styles["container"]}>
      <Header />
      <Container className={styles["main"]}>
        <Row className="px-md-0 px-3">
          <Col sm={12} className="position-relative text-center">
            <h1
              className={classNames(styles["title"], "font-blenny text-white box-shadow")}
            >
              The Team
            </h1>
          </Col>

          <Col
            sm={12}
            className={classNames("text-center", styles["text-sub"])}
          >
            <h3 className="text-uppercase text-yellow-100 font-acierdisplay letterspace2px">
              WHO ARE THESE CRAZY PEOPLE?
            </h3>
          </Col>

          <Row className={classNames("text-center text-white mt-8 p-0 mx-0")}>
            {TEAMS.map((team, index) => (
              <Col
                key={`team-${index}`}
                className={classNames(
                  "text-center text-white mb-5 mb-lg-0 col-lg-3 col-12 col-md-6 px-lg-5 d-flex flex-column justify-content-between",
                  "animate__animated  wow animated_duration3s",
                  { animate__fadeInRight: index >= 2 },
                  { animate__fadeInLeft: index < 2 },
                  styles["team-item"]
                )}
              >
                <div>
                  <img className="mb-4" src={team.imgSrc} alt={team.name} />

                  <h3
                    className={classNames(
                      styles["name"],
                      "font-acierdisplay text-lightred-100 m-0"
                    )}
                  >
                    {team.name}
                  </h3>
                  <div
                    className={classNames(
                      styles["position"],
                      "font-blenny text-yellow-200 mb-2"
                    )}
                  >
                    {team.position}
                  </div>
                  {team.description.map((desc, index) => (
                    <div
                      key={`description-${index}`}
                      className={classNames(
                        styles["team-description"],
                        "font-acierdisplay text-white",
                        { "mb-2": team.description.length - 1 === index }
                      )}
                    >
                      {desc}
                    </div>
                  ))}
                </div>

                <div className="text-center">
                  <a
                    href={team.to}
                    className={classNames(
                      styles["link-btn"],
                      "d-inline-block text-decoration-none text-black font-arial fw-bold"
                    )}
                    target="_blank"
                  >
                    {team.buttonText}
                  </a>
                </div>
              </Col>
            ))}
          </Row>

          <Col sm={12} className="mt-8">
            <h1
              className={classNames(
                styles["keep-up-text"],
                "font-blenny text-white"
              )}
            >
              KEEP UP WITH US
            </h1>
          </Col>

          <Col
            sm={12}
            className={classNames("text-center", styles["keep-up-sub"])}
          >
            <h3 className="text-uppercase text-lightyellow font-acierdisplay">
              FOLLOW OUR PROGRESS
            </h3>
          </Col>

          <Col
            className={classNames(
              "text-center col-12 col-lg-10 mx-auto",
              styles["description"]
            )}
          >
            <p className="text-uppercase text-lightyellow font-cooperBlack text-white mt-5">
              We will hold monthly online meetings connecting our supporters
              with the artists and filmmakers who are bringing{" "}
              <span
                className={classNames(styles["coffee-wars"], "text-orange-500")}
              >
                COFFEE WARS
              </span>{" "}
              to the world and creating a franchise of sequels. And when the
              films are complete, our nft holders will get first premiere access
              to the film before anyone else has seen it. Our intention is to
              shake things up. Enough waiting around for some big studio with
              their bureacratic pencil-pushers. We are{" "}
              <span
                className={classNames(styles["coffee-wars"], "text-orange-500")}
              >
                COFFEE WARS
              </span>
              , and we are ready to get our caffeine on!
            </p>
          </Col>

          <Col
            sm={12}
            className={classNames("text-center mt-4", styles["discord-btn"])}
          >
            <a
              href="https://discord.gg/KDF5HKa3mb"
              className="d-inline-block text-uppercase text-white text-decoration-none font-acierdisplay rounded-2"
              target="_blank"
            >
              DISCORD
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default TeamPage;
